import { Plugin, Store } from 'vuex';

import { RootState } from 'src/types/RootState';

/**
 * Vuex plugin which automatically rewrite locations in localStorage.
 *
 * @param store                     Vuex Store Object.
 */
const rewritingLocations: Plugin<RootState> = (
    store: Store<RootState>,
): void => {
    store.watch(
        () => store.getters['weatherModule/getLocations'],
        (location) => {
            if (!location) return;
            localStorage.setItem('locations', JSON.stringify(location));
        },
    );
};

export default rewritingLocations;
