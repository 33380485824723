import { createModule } from 'vuexok';
import store from '@/store';
import WeatherState from './state';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export const weatherModule = createModule('weatherModule', {
    namespaced: true,
    state: new WeatherState(),
    actions: actions,
    mutations: mutations,
    getters: getters,
});

weatherModule.register(store);
