/**
 * Weather constructor options.
 */
type ConstructorOptions = {
    temperature: number;
    city: string;
    country: string;
    feelsLikeTemp: number;
    pressure: number;
    humidity: number;
    weatherDescription: string;
    iconCode: string;
    windSpeed: number;
    visibility: number;
};

/**
 * Weather model from API.
 */
type WeatherInfo = {
    main: {
        temp: number;
        pressure: number;
        humidity: number;
        feels_like: number;
    };
    name: string;
    sys: {
        country: string;
    };
    weather: [
        {
            description: string;
            icon: string;
        },
    ];
    visibility: number;
    wind: {
        speed: number;
    };
};

/**
 *
 */
export class Weather {
    /**
     *  Temperature in location.
     */
    readonly temperature: number;

    /**
     * City name.
     */
    readonly city: string;

    /**
     * Country name.
     */
    readonly country: string;

    /**
     * Temperature. This temperature parameter accounts for the human perception of weather.
     */
    readonly feelsLikeTemp: number;

    /**
     * The temperature to which air must be cooled to become saturated with water vapor.
     */
    readonly dewPoint: number;

    /**
     * Visibility.
     */
    readonly visibility: string;

    /**
     * Athmosphere pressure.
     */
    readonly pressure: number;

    /**
     * Code for icon showing.
     */
    readonly iconCode: string;

    /**
     * Humidity in location.
     */
    readonly humidity: number;

    /**
     * Weather condition within the group.
     */
    readonly weatherDescription: string;

    /**
     * Wind speed.
     */
    readonly windSpeed: string;

    /**
     * Max humidity.
     */
    readonly maxHumidity = 100;

    /**
     * Constant for calculating dew point.
     */
    readonly approximation = 5;

    constructor(data: ConstructorOptions) {
        this.temperature = Math.round(data.temperature);
        this.city = data.city;
        this.country = data.country;
        this.feelsLikeTemp = Math.round(data.feelsLikeTemp);
        this.dewPoint = Math.round(
            data.temperature -
                (this.maxHumidity - data.humidity) / this.approximation,
        );
        this.visibility = (data.visibility / 1000).toFixed(1);
        this.pressure = data.pressure;
        this.iconCode = data.iconCode;
        this.humidity = data.humidity;
        this.weatherDescription = data.weatherDescription;
        this.windSpeed = data.windSpeed.toFixed(1);
    }

    /**
     * Creates `Weather` instance from the data provided by API.
     *
     * @param weatherInfo                   `Weather` data provided by API.
     */
    public static fromApiWeather(weatherInfo: WeatherInfo): Weather {
        const weather: ConstructorOptions = {
            temperature: weatherInfo.main.temp,
            city: weatherInfo.name,
            country: weatherInfo.sys.country,
            feelsLikeTemp: weatherInfo.main.feels_like,
            pressure: weatherInfo.main.pressure,
            humidity: weatherInfo.main.humidity,
            weatherDescription: weatherInfo.weather[0].description,
            iconCode: weatherInfo.weather[0].icon,
            windSpeed: weatherInfo.wind.speed,
            visibility: weatherInfo.visibility,
        };
        return new Weather(weather);
    }
}
