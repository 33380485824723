import Vue from 'vue';
import Vuex from 'vuex';

import { rewritingLocations } from '../utils/storePlugins';

Vue.use(Vuex);

export default new Vuex.Store({
    plugins: [rewritingLocations],
});
