import Vue from 'vue';
import App from './components/app/App.vue';
import store from './store';
import vuetify from './plugins/vuetify';
import VuePlaceAutocomplete from 'vue-place-autocomplete';

Vue.use(VuePlaceAutocomplete);

Vue.config.productionTip = false;

new Vue({
    store,
    vuetify,
    render: (h) => h(App),
}).$mount('#app');
