import { weatherModule } from '@/store/modules/weatherModule';
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class StartupScreen extends Vue {
    /**
     * Rule for city input field.
     */
    private inputRule = [
        (v: string) => !!v || 'Field required',
        (v: string) => !/[0-9]/.test(v) || 'Сan not contain numbers',
    ];

    /**
     * Input field value.
     */
    public location = '';

    /**
     * Add new location.
     */
    public addLocation(): void {
        weatherModule.actions.addLocation(this.location);
        this.location = '';
    }
}
