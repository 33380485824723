import { Vue, Component } from 'vue-property-decorator';
import StartupScreen from '../startup-screen/StartupScreen.vue';
import { weatherModule } from '@/store/modules/weatherModule';
import WeatherScreen from '../weather-screen/WeatherScreen.vue';

@Component({
    components: {
        'startup-screen': StartupScreen,
        'weather-screen': WeatherScreen,
    },
})
export default class App extends Vue {
    /**
     * Indicator, whether location has been chosen.
     */
    private get isLocationChosen(): boolean {
        return weatherModule.getters.getLocations.length > 0 ? true : false;
    }
}
