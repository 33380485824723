import { Weather } from '@/models/Weather';
import { RootState } from 'src/types/RootState';
import { GetterTree } from 'vuex';
import { weatherModule } from '.';
import WeatherState from './state';

export function getLocations(): string[] {
    return weatherModule.state.locations;
}

export function getWeatherLocationInfo(): Array<{
    key: string;
    value: Weather | null;
}> {
    return weatherModule.state.locationsInfoList;
}

export default { getLocations, getWeatherLocationInfo } as GetterTree<
    WeatherState,
    RootState
>;
