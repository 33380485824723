import { RootState } from 'src/types/RootState';
import { ActionContext, ActionTree } from 'vuex';
import WeatherState from './state';
import axios from 'axios';
import { weatherModule } from '.';
import { Weather } from '@/models/Weather';

export async function getLocationsWeather(
    store: ActionContext<WeatherState, RootState>,
): Promise<void> {
    weatherModule.mutations.setLocationInfoKeys();
    store.state.locations.forEach((location) => {
        axios
            .get(
                `https://api.openweathermap.org/data/2.5/weather?q=${location}&appid=${store.state.apiKey}`,
                {
                    params: {
                        units: 'metric',
                    },
                },
            )
            .then(async (res) => {
                const weatherInfoFromApi = Weather.fromApiWeather(res.data);
                weatherModule.mutations.setWetherLocationInfo(
                    weatherInfoFromApi,
                );
            });
    });
}

export async function addLocation(
    _store: ActionContext<WeatherState, RootState>,
    location: string,
): Promise<void> {
    weatherModule.mutations.addLocation(location);
    weatherModule.actions.getLocationsWeather();
}

export async function reorderLocations(
    _store: ActionContext<WeatherState, RootState>,
    locations: string[],
): Promise<void> {
    weatherModule.mutations.reorderLocations(locations);
    weatherModule.actions.getLocationsWeather();
}

export async function deleteLocation(
    _store: ActionContext<WeatherState, RootState>,
    location: string,
): Promise<void> {
    weatherModule.mutations.deleteLocation(location);
    weatherModule.actions.getLocationsWeather();
}

export default {
    addLocation,
    getLocationsWeather,
    deleteLocation,
    reorderLocations,
} as ActionTree<WeatherState, RootState>;
