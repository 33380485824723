import { Vue, Component } from 'vue-property-decorator';
import { weatherModule } from '@/store/modules/weatherModule';
import draggable from 'vuedraggable';

@Component({
    components: {
        'draggable': draggable,
    },
})
export default class Settings extends Vue {
    /**
     * Rule for city input field.
     */
    private inputRule = [
        (v: string) => !!v || 'Field required',
        (v: string) => !/[0-9]/.test(v) || 'Сan not contain numbers',
        (v: string) =>
            !this.locationsList.includes(v) || 'The city already exists',
    ];

    /**
     * Input field value.
     */
    public location = '';

    /**
     * Getter, which return list of user saved locations.
     */
    public get locationsList(): string[] {
        return weatherModule.getters.getLocations;
    }

    /**
     * Setter, which reorder user saved locations.
     */
    public set locationsList(locations: string[]) {
        weatherModule.actions.reorderLocations(locations);
    }

    /**
     * Add new location.
     */
    public addLocation(): void {
        weatherModule.actions.addLocation(this.location);
        this.location = '';
    }

    /**
     * Delete location from list (and from localStorage).
     *
     * @param location              Location name.
     */
    public deleteLocation(location: string): void {
        weatherModule.actions.deleteLocation(location);
    }
}
