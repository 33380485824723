import { Weather } from '@/models/Weather';
import { MutationTree } from 'vuex';
import WeatherState from './state';

export function addLocation(
    state: WeatherState,
    location: string,
): void | Error {
    const repetition = state.locations.find((item) => {
        return item === location;
    });
    if (repetition) return new Error('Location has already!');

    state.locations.push(location);
}

export function setLocationInfoKeys(state: WeatherState): void {
    state.locationsInfoList = [];
    state.locations.forEach((location) => {
        state.locationsInfoList.push({
            key: location,
            value: null,
        });
    });
}

export function reorderLocations(
    state: WeatherState,
    locations: string[],
): void {
    state.locations = locations;
}

export function setWetherLocationInfo(
    state: WeatherState,
    weatherInfo: Weather,
): void {
    const locationInfo = state.locationsInfoList.find(
        (item) => item.key === weatherInfo.city,
    );
    if (locationInfo) {
        locationInfo.value = weatherInfo;
    }
}

export function deleteLocation(state: WeatherState, location: string): void {
    const locationIndex = state.locations.findIndex(
        (item) => item === location,
    );
    if (locationIndex || locationIndex === 0) {
        state.locations.splice(locationIndex, 1);
    }
}

export default {
    addLocation,
    setWetherLocationInfo,
    deleteLocation,
    setLocationInfoKeys,
    reorderLocations,
} as MutationTree<WeatherState>;
