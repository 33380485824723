import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class WeatherCard extends Vue {
    /**
     *  Temperature in location.
     */
    @Prop({
        default: false,
        type: Number,
    })
    temperature!: number;

    /**
     * City name.
     */
    @Prop({
        default: false,
        type: String,
    })
    city!: string;

    /**
     * Country name.
     */
    @Prop({
        default: false,
        type: String,
    })
    country!: string;

    /**
     * Temperature. This temperature parameter accounts for the human perception of weather.
     */
    @Prop({
        default: false,
        type: Number,
    })
    feelsLikeTemp!: number;

    /**
     * Athmosphere pressure.
     */
    @Prop({
        default: false,
        type: Number,
    })
    pressure!: number;

    /**
     * Visibility.
     */
    @Prop({
        default: false,
        type: Number,
    })
    humidity!: number;

    /**
     * The temperature to which air must be cooled to become saturated with water vapor.
     */
    @Prop({
        default: false,
        type: Number,
    })
    dewPoint!: number;

    /**
     * Weather condition within the group.
     */
    @Prop({
        default: false,
        type: String,
    })
    weatherDescription!: string;

    /**
     * Code for icon showing.
     */
    @Prop({
        default: false,
        type: String,
    })
    iconCode!: string;

    /**
     * Wind speed.
     */
    @Prop({
        default: false,
        type: String,
    })
    windSpeed!: string;

    /**
     * Visibility.
     */
    @Prop({
        default: false,
        type: String,
    })
    visibility!: string;
}
