import { Weather } from '@/models/Weather';
import { weatherModule } from '@/store/modules/weatherModule';
import { Vue, Component } from 'vue-property-decorator';
import WeatherCard from './components/weather-card/WeatherCard.vue';
import Settings from '../settings/Settings.vue';

@Component({
    components: {
        'weather-card': WeatherCard,
        'settings': Settings,
    },
})
export default class StartupScreen extends Vue {
    /**
     * Indicator that shows whether the settings are open.
     */
    public isSettings = false;

    /**
     * Getter, which return all location with full weather information.
     */
    public get locationsInfo(): Array<{ key: string; value: Weather | null }> {
        return weatherModule.state.locationsInfoList;
    }

    /**
     * Open or close settings.
     */
    public switchSettings(): void {
        this.isSettings = !this.isSettings;
    }

    /**
     * Get information about wheather in locations.
     */
    public mounted(): void {
        weatherModule.actions.getLocationsWeather();
    }
}
