import { Weather } from '@/models/Weather';

/**
 * User Vuex state.
 */
export default class WeatherState {
    /**
     * Key for weather API.
     */
    readonly apiKey: string;

    /**
     * List of user saved locations.
     */
    public locations: string[];

    /**
     * List of locations weather info.
     */
    public locationsInfoList: Array<{ key: string; value: Weather | null }>;

    constructor() {
        this.apiKey = 'f17215cf0b7cf7227a44f406b59e3be2';
        this.locations = localStorage.getItem('locations')
            ? JSON.parse(<string>localStorage.getItem('locations'))
            : [];
        this.locationsInfoList = [];
    }
}
